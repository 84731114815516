import React from 'react';
import './about.css';

const About = ({ aboutMeRef, styleGB }) => {
  return (
    <div ref={aboutMeRef} className="about-container">
      <h2 style={styleGB}>About Me</h2>
      <div className="about-content">
        <div className="about-text">
          <p>
            I began my journey in the tech world by joining the Accenture Bootcamp (a leading IT company) in 2021, where I earned an internship that launched my career in IT.
            During this time, I dedicated a year to mastering AEM front-end development and Salesforce CMS development, while also expanding my knowledge of a wide array of
            development tools and technologies.
            <br /><br />
            I found a lot of joy in working on my own small projects, such as creating webpages and learning more about the front-end and back-end aspects of web development.
            I explored how databases and APIs function, further strengthening my skills. I also explored how smart contracts and blockchain development work.
            <br /><br />
            Then I created a small Python trading bot that fetched data from Binance to analyze increasing or decreasing price candles within specific time periods.
            <br /><br />
            In 2023, I joined SEB (a banking institution) as a Bank Operations Specialist, where I began exploring automation possibilities in the banking sector.
            I learned about the robotic solutions used in banking and spent time developing scripts to automate daily tasks, enhancing the efficiency of routine operations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;