import React, { useEffect, useState } from 'react'
import './game.css'

import SingleCard from './SingleCard';

const cardImages = Array.from({ length: 28 }, (_, i) => ({ src: require(`../../images/game/c${i + 1}.jpg`), matched: false }));

const Game = ({styleGB}) => {
    const [cards,setCards] = useState([]);
    const [turns, setTurns] = useState(0)
    const [choiceOne, setChoiceOne] = useState(null)
    const [choiceTwo, setChoiceTwo] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [showPopup, setShowPopup] = useState(false)

    const shuffleCards =() => {
        const shuffledCards = [...cardImages, ...cardImages]
        .sort(() => Math.random() - 0.5)
        .map((card) => ({ ...card,id: Math.random() }))

        setChoiceOne(null)
        setChoiceTwo(null)
        setCards(shuffledCards)
        setTurns(0)
        setShowPopup(false)
    }

    const handleChoice = (card) => {
        choiceOne ? setChoiceTwo(card) : setChoiceOne(card)
    }

    useEffect(() => {
        if(choiceOne && choiceTwo) {
            setDisabled(true)

            if (choiceOne.src === choiceTwo.src) {
                setCards(prevCards => {
                    return prevCards.map(card => {
                        if (card.src === choiceOne.src){
                            return {...card, matched: true}
                        }else {
                            return card
                        }
                    })
                })
                resetTurn()
            } else {
                setTimeout(() => resetTurn(), 1000)
            }
        }
    }, [choiceOne, choiceTwo])

    useEffect(() => {
        if (cards.length > 0 && cards.every(card => card.matched)) {
            setShowPopup(true)
        }
    }, [cards])

    const resetTurn = () => {
        setChoiceOne(null)
        setChoiceTwo(null)
        setTurns(prevTurns => prevTurns + 1 )
        setDisabled(false)
    }

    const getScoreMessage = (turns) => {
        if (turns === 28) return "Wow, you have an incredible memory! Excellent job!";
        if (turns > 28 && turns <= 34) return "Great work! You have an excellent memory!";
        if (turns > 34 && turns <= 50) return "Well done! That's a really good score!";
        if (turns > 50 && turns <= 75) return "Not bad at all! Keep practicing, and you'll get even better!";
        return "Don't worry! Memory is like a muscle—the more you practice, the stronger it gets. Keep going!";
    };

    useEffect(() => {
        shuffleCards()
    }, [])

  return (
    <div className="game-g" id='game'>
        <p className='h1-g'> Not advisable on mobile versions</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button style={styleGB} className='button-g' onClick={shuffleCards}>New Game</button>
        </div>
        <p className='result' style={styleGB}>Turns: {turns}</p>

        <div className="card-grid">
            {cards.map(card => (
                <SingleCard 
                key={card.id} 
                card={card}
                handleChoice={handleChoice}
                flipped={card === choiceOne || card === choiceTwo || card.matched}
                disabled={disabled}
                />
            ))}
        </div>
        {showPopup && (
            <div className="popup">
                <div className="popup-inner">
                    <p>🎉 Congratulations! You won the game in {turns} turns! 🎯</p>
                    <p>Best possible result is 28</p>
                    <p>{getScoreMessage(turns)}</p>
                    <button onClick={shuffleCards}>Play Again</button>
                    <button onClick={() => setShowPopup(false)} style={{ marginLeft: '10px' }}>Exit</button>
                </div>
            </div>
        )}
    </div>
  )
}

export default Game;
