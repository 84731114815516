const dataWebDevelopment = [
    {
        id: 24,
        img: "p7.png",
        title: "CrankyBicycle V2",
        tools: "#react",
        link: "https://crankybicycklev2.netlify.app",
    },
    {
        id: 25,
        img: "p6.png",
        title: "CarMaster",
        tools: "#react",
        link: "https://carmasterr.netlify.app/",
    },
    {
        id: 26,
        img: "p5.png",
        title: "Top News",
        tools: "#react #api",
        link: "https://top-news-vg.netlify.app/",
    },
    {
        id: 27,
        img: "p4.png",
        title: "Weather App",
        tools: "#react #api",
        link: "https://turninternetoff.netlify.app/",
    },
    {
        id: 28,
        img: "p3.png",
        title: "Sir Shrek",
        tools: "#react",
        link: "https://sirshrek.netlify.app/",
    },
    {
        id: 29,
        img: "p2.png",
        title: "NetFlexo",
        tools: "#javaScript #html #css",
        link: "https://netflexo.netlify.app/",
    },
    {
        id: 30,
        img: "p1.png",
        title: "CrankyBicycle",
        tools: "#javaScript #html #css",
        link: "https://crankybicycle.netlify.app/",
    },
];

const excelMacro = [
    {
        id: 1,
        img: "e1.png",
        title: "Task Counter",
        tools: "#excel #vba",
        desc: "Daily workers count various types of items and record the time spent on them. At the end of the day, they need to review the counted items and report them to the correct statistics section. This macro helps by identifying which task belongs to a specific statistics section and automatically inputs it there, reducing manual work. If you want to see the full code of this project, click on the GitHub icon.",
        file1: "/TaskCounterV3.2.0.xlsm",
        file2: "/taskCounterData.xlsx",
        link: "https://github.com/grzibovskis/TaskCounterExcel"
    },
    {
        id: 2,
        img: "e2.png",
        title: "Reconcile",
        tools: "#excel #vba",
        desc: "After the macro runs, it excludes specific information from the message in the file, connects with another macro, and sends this information further to reduce repetitive tasks and human errors. The macro is designed to react to the provided message, therefore Excel files are not included. The code logic can be accessed through the GitHub link.",
        link: "https://github.com/grzibovskis/ReconcileExcel"
    },
    {
        id: 3,
        img: "e3.png",
        title: "Shipment Check",
        tools: "#excel #vba",
        desc: "This macro processes a new delivery order list by comparing it with existing information. If a new delivery is detected, a blue line appears at the bottom, allowing users to track new entries easily. It also updates the status of deliveries by moving already delivered items to the archive for better organization. Additionally, the macro monitors price or delivery date changes for specific codes and highlights discrepancies by displaying 'FALSE' when differences are found. If you want to see the full code of this project, click on the GitHub icon.",
        file1: "/Shipment_Check.xlsm",
        file2: "/Product_list.xls",
        link: "https://github.com/grzibovskis/Shipping_check"
    }
    
];

const otherProjects = [
    {
        id: 1,
        img: "p1.png",
        title: "Useful Information",
        tools: "#Python",
        desc: "This Python script automates the creation of a folder containing information about the USA while also interacting with other applications on your computer. It generates a 'Useful Information' folder on the desktop and saves a file listing all U.S. states. Additionally, the script seamlessly opens another application, performs automated tasks, and enhances workflow efficiency by executing predefined actions. If you want to see the full code of this project, click on the GitHub icon.",
        file1: "/usefulInformation.zip",
        link: "https://github.com/grzibovskis/UsefulInformation"
    },
    {
        id: 2,
        img: "p2.png",
        title: "Ticket Generator",
        tools: "#Python",
        desc: "This Python script, called Ticket Generator, automates the process of submitting a ticket by navigating to a specified link and inputting the required values automatically. It eliminates the need for manual entry, saving time and reducing human error. Designed for a single-ticket workflow, this script ensures efficiency by streamlining the submission process with minimal user intervention. Click on the GitHub icon if you want to see snip of code for one ticket form.",
        link: "https://github.com/grzibovskis/ticketGenerator"
    }
];

export { dataWebDevelopment, excelMacro, otherProjects };