import React from 'react'
import './info.css'
import GMAIL from '../../images/design/gmail.png'
import LINKEDIN from '../../images/design/linkedin.png'
import YOUTUBE from '../../images/design/youtube.png'
import GITHUB from '../../images/design/github.png'



const Info = (props) => {
  return (
    <div className='info-container-bg'>
      <div className='info-container-bg'>

    <div className='text-youtube-container'>
      <div className='block cText'>
        <h1><span style={props.styleLight}>Hi! My name is Verners</span><span style={props.styleGB}>.</span></h1>
        <p className='small-bio'>There’s a video of me creating a website from scratch in React. I’ve now practiced how to pronounce folder correctly, in most of the time.
        There’s also a small game at the bottom if you're up for the challenge—it might just bring back some childhood memories!
        <br />
        <br />
        You can find a little bit about me after the portfolio section. Or click 
        <span className="clickable" style={{ cursor: 'pointer'}} onClick={props.scrollToAboutMe}>
          <span style={props.styleGB}><b> HERE </b></span>
        </span>
        </p>
      </div>
      {/* YOUTUBE */}
      <div className='container-youtube'>
        <iframe  src="https://www.youtube.com/embed/itbNDFwjJVs?si=hh9hLMgzUDo10eC_" title="YouTube video player" 
          frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
        </iframe>
      </div>
    </div>

      <div className='block2 know'>
        {/* SKILLS */}
        <div className='skills'>
          <div className='webdev'>
            <p>
            Tools: <span style={props.styleGB}>HTML, CSS, JavaScript, PHP, SQL, XML, SASS, Less, Java, C++, C#, Python,
            React, MySQL, MongoDB, Bootstrap, Material UI, GIT, Django.</span>
            </p>
          </div>
          <div className='webdev'>
            <p>
              Adobe: <span style={props.styleGB}>Adobe Analytics, Adobe Target, Adobe Campaign, Adobe Experience Manager, Adobe Photoshop, Adobe Premiere Pro.</span>
            </p>
            <br />
          </div>
          <div className='webdev'>
            <p>
            Salesforce: <span style={props.styleGB}>APEX, SOQL, Administration</span>
            </p>
            <br />
          </div>
          <div className='webdev'>
            <p>
            Automation: <span style={props.styleGB}>BLue Prism, Excel VBA</span>
            </p>
            <br />
          </div>
        </div>
        {/* CERTIFICATION */}
        <div className='block3 certification'>
          <h3>My Certifications</h3>
          <div className='line2' style={props.stylesBG}></div>
            <ul className='certification-ul' >
              <li>Salesforce Certified Associate</li>
              <li>Web Developer Zero-To-Mastery</li>
            </ul>
          </div>
        </div>
        {/* LINKS */}
        <div className='contacts'>
              <a href="mailto:grzybowski.work@gmail.com" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={GMAIL} alt="gmail" /></a>
              <a href="https://www.linkedin.com/in/verners-grzibovskis/" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={LINKEDIN} alt="linkedin" /></a>
              <a href="https://www.youtube.com/watch?v=YoVU6-xlWfU&t=32s" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={YOUTUBE} alt="youtube" /></a>
              <a href="https://github.com/grzibovskis" target="_blank" rel="noreferrer"><img className='icon container-link-box' src={GITHUB} alt="github"/></a>
            <br />
              <a className='button container-link-box' href="https://www.credly.com/users/verners-grzibovskis/badges" target="_blank" rel="noreferrer">Credly</a>
              <a className='button container-link-box' href="https://trailblazer.me/id/accenture-latvia-verners-grzibovskis" target="_blank" rel="noreferrer">Trailblazer</a>
        </div>
      </div>
    </div>
  )
}

export default Info