import './work.css';
import LINK from '../../images/design/external-link.png';
import tut1 from '../../images/excelProjects/tut/t1.png';
import e1 from '../../images/excelProjects/e1.png';
import e2 from '../../images/excelProjects/e2.png';
import e3 from '../../images/excelProjects/e3.png';
import p1 from '../../images/python/p1.png';
import p2 from '../../images/python/p2.png';
import GITHUB from '../../images/design/light-github.png'
import { Tilt } from 'react-tilt';

const imageMapperEM = {
  "e1.png": e1,
  "e2.png": e2,
  "e3.png": e3,
};
const imageMapperPY = {
  "p1.png": p1,
  "p2.png": p2,
};

const Work = ({ dataWebDevelopment, excelMacro, otherProjects, styleCard, styleGB }) => {
  return (
    <div>
      <h1 className='portfolio' style={styleGB}>Python</h1>
      <div className='work-container'>
        {otherProjects.map((value, index) => (
          <Tilt key={value.id}>
          <div style={styleCard} className='card'>
              <div >
              <img className='photo' src={imageMapperPY[value.img]} alt={value.title} />
              </div>
              <div className='desc'>
                  <h1 style={styleCard}>{value.title}</h1>
                  <p style={styleCard}>{value.tools}</p>
                  {value.file1 && (
                    <a href={value.file1} download>
                      <button className='download-button'>Python.exe</button>
                    </a>
                  )}
                  <p style={styleCard} className='desc'>{value.desc}</p>
                  <a className='codelink' href={value.link} target="_blank" rel="noreferrer">
                    <img className='icon light-github' src={GITHUB} alt="link" />
                  </a>
              </div>
          </div>
        </Tilt>
        ))}
      </div>
      <h1 className='portfolio' style={styleGB}>EXCEL MACROS</h1>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ 
          width: window.innerWidth > 1000 ? '600px' : window.innerWidth < 320 ? '100%' : '300px', 
          textAlign: 'center', 
          padding: '10px', 
          border: '1px solid #ccc', 
          borderRadius: '10px', 
          backgroundColor: '#f9f9f9', 
          boxShadow: '0 0 10px rgba(0,0,0,0.2)' 
        }}>
          <p style={{ color: 'black' }}>If the macro is not running and you see this error, you need to unblock macros in this Excel file.</p>
          <img src={tut1} alt="Macros error FIX" style={{ width: '100%', marginBottom: '10px' }} />
          <p style={{ color: 'black' }}>Go to the downloaded files location, right-click the file, and choose <strong>Properties</strong>.</p>
          <p style={{ color: 'black' }}>Then tick <strong>Unblock</strong> in the General section.</p>
          <p style={{ color: 'black' }}>The macro should work now!</p>
        </div>
      </div>
      <br />
      <br />
      <div className='work-container'>
        {excelMacro.map((value, index) => (
          <Tilt key={value.id}>
            <div style={styleCard} className='card'>
                <div >
                <img className='photo' src={imageMapperEM[value.img]} alt={value.title} />
                </div>
                <div className='desc'>
                    <h1 style={styleCard}>{value.title}</h1>
                    <p style={styleCard}>{value.tools}</p>
                    {value.file1 && (
                      <a href={value.file1} download>
                        <button className='download-button'>Excel Macros</button>
                      </a>
                    )}
                    {value.file2 && (
                      <a href={value.file2} download>
                        <button className='download-button'>Testing data</button>
                      </a>
                    )}
                    <p style={styleCard} className='desc'>{value.desc}</p>
                    <a className='codelink' href={value.link} target="_blank" rel="noreferrer">
                      <img className='icon light-github' src={GITHUB} alt="link" />
                    </a>
                </div>
            </div>
          </Tilt>
        ))}
      </div>

      <h1 className='portfolio' style={styleGB}>WEB DEVELOPMENT</h1>
      <div className='work-container'>
        {dataWebDevelopment.map((value, index) => (
          <Tilt key={value.id}>
            <div className='card'>
              <div>
                <img className='photo' src={require(`../../images/${value.img}`)} alt="" />
              </div>
              <div className='desc'>
                <h1>{value.title}</h1>
                <p>{value.tools}</p>
                <a href={value.link} target="_blank" rel="noreferrer">
                  <img className='codelink icon' src={LINK} alt="link" />
                </a>
              </div>
            </div>
          </Tilt>
        ))}
      </div>
    </div>
  );
};

export default Work;