import { useState, useRef } from 'react';
import Toggle from './components/Toggle/Toggle';
import Info from './components/Info/Info';
import Work from './components/Work/Work';
import Game from './components/Game/Game';
import { dataWebDevelopment, excelMacro, otherProjects } from './database/Data';
import About from './components/About/About';

const App = () => {
  const [toggled, setToggled] = useState(false);
  const aboutMeRef = useRef(null); // Create a ref for About component

  const handleClick = () => setToggled((prevBtn) => !prevBtn);

  const scrollToAboutMe = () => {
    if (aboutMeRef.current) {
      aboutMeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const stylesMain = { backgroundColor: toggled ? "#223543" : "#f8fdff", color: toggled ? "#bad0de" : "#223543" };
  const stylesBG = { backgroundColor: toggled ? "#fdc765" : "#1969ff" };
  const styleLight = { color: toggled ? "#f8fdff" : "#161a26" };
  const styleGB = { color: toggled ? "#fdc765" : "#1969ff" };
  const styleCard = { backgroundColor: toggled ? "#161a26" : "#f8fdff", color: toggled ? "#f8fdff" : "#161a26" };
  const styleIC = { backgroundColor: toggled ? "#161a26" : "#f8fdff", boxShadow: toggled ? "0px 4px 30px black" : "0px 0px 10px black" };

  return (
    <div className='app-container' style={stylesMain}>
      <div>
        <div className='colorbox'></div>
        <Toggle toggled={toggled} onClick={handleClick} />
        <Info styleGB={styleGB} styleLight={styleLight} stylesBG={stylesBG} scrollToAboutMe={scrollToAboutMe} />
        <Work styleIC={styleIC} styleCard={styleCard} dataWebDevelopment={dataWebDevelopment} excelMacro={excelMacro} otherProjects={otherProjects} styleLight={styleLight} styleGB={styleGB} />
        <About aboutMeRef={aboutMeRef} styleGB={styleGB} />

        <Game styleGB={styleGB} />
        <div className='colorbox'></div>
      </div>
    </div>
  );
};

export default App;
